








































import { Component, Vue } from 'vue-property-decorator';
import myHeader from '@/components/Header.vue';
import myFooter from '@/components/Footer.vue';
import { getQuestions, putSubmitReset } from '@/api/courseWork/myHomework/myHomework.ts';

@Component({
    components: {
        myHeader,
        myFooter
    }
})
export default class MyHomework extends Vue {
    data = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        crs_id: 0,
        // eslint-disable-next-line @typescript-eslint/camelcase
        type_id: 0,
        // eslint-disable-next-line @typescript-eslint/camelcase
        semester_id: 0
    };

    // 题目列表
    questionsData = [];
    // 课程名字
    crsName = '';
    // 题目数
    totalQues = '';
    // 加载状态
    loadingIndex = true;

    mounted() {
        this.getData();
    }

    getData() {
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.data.crs_id = parseInt(this.$route.query.crsId as string);
        this.crsName = this.$route.query.crsName as string;
        this.totalQues = this.$route.query.totalQues as string;
        // eslint-disable-next-line @typescript-eslint/camelcase
        const checksem = JSON.parse(localStorage.getItem('checkSemester') as string);
        if (checksem !== null) {
            this.data.semester_id = parseInt(checksem.id);
        } else {
            this.data.semester_id = parseInt(localStorage.getItem('semesterId') as string);
        }
        getQuestions(this.data).then((res: any) => {
            if (res.code === 200) {
                this.loadingIndex = false;
                this.questionsData = res.data;
                console.log(this.loadingIndex);
            } else {
                this.$message.error(res.error);
            }
        }).catch((error: any) => {
            console.log(error);
        });
    }

    // 渲染时自动调用1转换01
    courseTitleValue(index: number) {
        index++;
        return index >= 10 ? index.toString() : '0' + index;
    }

    // 跳转到查看解析
    goAnswerAnalysis(andId: number, sectionName: string) {
        this.$router.push({
            path: '/answerAnalysis',
            query: {
                ansId: andId.toString(),
                crsName: this.crsName,
                sectionName: sectionName,
                crsId: this.$route.query.crsId as string,
                totalQues: this.$route.query.totalQues as string
            }
        });
    }

    // 跳转到重做
    goRedo(quesId: any, sectionName: string, ansId: number) {
        const data = {
            // eslint-disable-next-line @typescript-eslint/camelcase
            ans_id: ansId
        };
        putSubmitReset(data).then((res: any) => {
            console.log(res);
            this.$router.push({
                path: '/answerSheet',
                query: {
                    quesId: quesId,
                    crsName: this.crsName,
                    sectionName: sectionName,
                    crsId: this.$route.query.crsId as string,
                    totalQues: this.$route.query.totalQues as string
                }
            });
        }).catch((error: any) => {
            console.log(error);
        });
    }

    // 跳转到去完成
    goNotFinished(quesId: any, sectionName: string) {
        this.$router.push({
            path: '/answerSheet',
            query: {
                quesId: quesId,
                crsName: this.crsName,
                sectionName: sectionName,
                crsId: this.$route.query.crsId as string,
                totalQues: this.$route.query.totalQues as string
            }
        });
    }
}
