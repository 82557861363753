import { get, put } from '@/utils/http.js';

/*
function:获取试题列表
author:ysl
time:2021/03/22
*/

export function getQuestions(data: any) {
    return get('/answer/questions', data);
}

/*
function:重做
author:ysl
time:2021/03/22
*/

export function putSubmitReset(data: any) {
    return put('/answer/mobile/submit/reset', data);
}
